// https://api.gwscalculator.cloudnowtech.com
// const apiURL = 'https://api.gwscalculator.cloudnowtech.com/';
// https://dev-api.gwscalculator.cloudnowtech.com/getRoleList.php
// process.env.https://dev-api.gwscalculator.cloudnowtech.com/;


/* Dev */
export const apiUrl = 'https://dev-api.gwscalculator.cloudnowtech.com/';

/* Prod */
//export const apiUrl = 'hhttps://api.gwscalculator.cloudnowtech.com/';
