import React, { useState, ChangeEvent, FormEvent, MouseEvent, useEffect } from "react";

import { FormValues, FormErrors, initialFormValues, subplans } from "./helper/formHelpers";
import { triggerNotification } from "../component/Toster";

const GWSProductItemForm = (props: any) => {
  // addProductItem
  const [values, setValues] = useState<FormValues>(initialFormValues);
  const [errors, setErrors] = useState<FormErrors>({});
  const [priceVarifyPercentage, setPriceVarifyPercentage] = useState(props.priceVarifyPercentage);
  const [formError, setFormError] = useState<string | null>(null); // State to hold form error message

  useEffect(() => {
    setFormError(null);
  }, [props.priceVarifyPercentage]);

  useEffect(() => {
    setFormError(null);
  }, [props.googleGivenPriceShow]);

  useEffect(() => {
    if (props.findFormFlag) {
      document.getElementById('addProductBtn')?.click();
    }
  }, [props.findFormFlag]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    setFormError(null);
    const charCode = e.charCode;
    const char = String.fromCharCode(charCode);
    if (!/^\d$/.test(char) && char !== ".") {
      e.preventDefault();
    }
  };

  const validate = (fieldValues: FormValues = values, fieldName?: keyof FormValues): FormErrors => {
    let tempErrors: FormErrors = { ...errors };

    const checkField = (field: keyof FormValues) => {
      if (field === "productType") {
        if (!fieldValues.productType) {
          tempErrors.productType = "Plan is required";
        } else {
          delete tempErrors.productType;
        }
      }
      if (field === "subPlan") {
        if (!fieldValues.subPlan) {
          tempErrors.subPlan = "SkU is required";
        } else {
          delete tempErrors.subPlan;
        }
      }
      if (field === "licenses") {
        if (!fieldValues.licenses) {
          tempErrors.licenses = "Licenses are required";
        } else if (!/^\d{1,3}$/.test(fieldValues.licenses)) {
          tempErrors.licenses = "Must be a 1 to 3 digit number";
        } else {
          delete tempErrors.licenses;
        }
      }
      if (field === "googlePrice") {
        if (!fieldValues.googlePrice && props.googleGivenPriceShow) {
          tempErrors.googlePrice = "Google Price is required";
        } else if (isNaN(Number(fieldValues.googlePrice))) {
          tempErrors.googlePrice = "Must be a number";
        } else {
          delete tempErrors.googlePrice;
        }
      }
      if (field === "customerPrice") {
        if (!fieldValues.customerPrice) {
          tempErrors.customerPrice = "Customer Price is required";
        } else if (isNaN(Number(fieldValues.customerPrice))) {
          tempErrors.customerPrice = "Must be a number";
        } else {
          delete tempErrors.customerPrice;
        }
      }
    };

    if (fieldName) {
      checkField(fieldName);
    } else {
      for (let key in fieldValues) {
        checkField(key as keyof FormValues);
      }
    }

    setErrors(tempErrors);
    return tempErrors;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setFormError(null);
    const { name, value } = e.target;
    if (name === "licenses" && value.length > 3) {
      return;
    }
    setValues({
      ...values,
      [name]: value,
    });
    validate({ ...values, [name]: value }, name as keyof FormValues);
  };
  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setFormError(null);
    if (Object.keys(validate()).length === 0) {
      console.log(values);
      validProductPrice(values);
    }
  };
  const validProductPrice = (values: any) => {
    let res = props.googleGivenPriceShow ? comparePriceWithGoogleGivenPrice(values) : comparePriceWithoutGoogleGivenPrice(values);
    if (res == true || props.isSendQuote == true) {
      props.addProductItem(values);
      setValues(initialFormValues);
    } else {
      props.productPriceNotValid();
      // triggerNotification("error", "Alert", "Contact NK and Dinesh to get approvals for this price.", "topRight");
      // setFormError("Contact NK and Dinesh to get approvals for this price."); // Set form error message
      props.approvalModalShowFromItemForm(true);
    }
  };

  const comparePriceWithoutGoogleGivenPrice = (values: any) => {
    let result = 0;
    let productsList = values.products;
    let mapErrorFlag = [];

    let itemName = values.productType + "" + values.subPlan;

    if (itemName.toLocaleLowerCase() == "businessstarter" || itemName.toLocaleLowerCase() == "businessstandard") {
      let numberOfLicenses = parseInt(values.licenses);
      let checkRemainingLicenses = numberOfLicenses > 20 ? true : false;

      if (checkRemainingLicenses) {
        let CustomerGivenPrice: any = parseFloat(values.customerPrice).toFixed(2);
        let total_OfCustomerGivenPrice = numberOfLicenses * CustomerGivenPrice;
        let countAfterTwantyLicenses = numberOfLicenses - 20;

        let basePrice1 = props.productPriceDetails1[values.productType.toLocaleLowerCase()][values.subPlan.toLocaleLowerCase()];
        let basePrice2 = props.productPriceDetails2[values.productType.toLocaleLowerCase()][values.subPlan.toLocaleLowerCase()];

        let margin1 = basePrice1 * (priceVarifyPercentage / 100);
        let margin2 = basePrice2 * (priceVarifyPercentage / 100);

        let firstTwantyLicensesAmt = 20 * basePrice1 - 20 * margin1;
        const afterTwantyLicensesAmt = countAfterTwantyLicenses * basePrice2 - countAfterTwantyLicenses * margin2;

        let preTotal = firstTwantyLicensesAmt + afterTwantyLicensesAmt;

        if (total_OfCustomerGivenPrice > preTotal) {
          mapErrorFlag.push(true);
        } else {
          mapErrorFlag.push(false);
          result = result + 1;
        }
      } else {
        let perLicensesAmount = props.productPriceDetails1[values.productType.toLocaleLowerCase()][values.subPlan.toLocaleLowerCase()];
        let perLicensesWithMarginAmt = perLicensesAmount - calculateMarginAmount(perLicensesAmount);
        let CustomerGivenPrice = parseInt(values.customerPrice);
        if (CustomerGivenPrice > perLicensesWithMarginAmt) {
          mapErrorFlag.push(true);
        } else {
          mapErrorFlag.push(false);
          result = result + 1;
        }
      }
    } else {
      let perLicensesAmount = props.productPriceDetails1[values.productType.toLocaleLowerCase()][values.subPlan.toLocaleLowerCase()];
      // let perLicensesWithMarginAmt = perLicensesAmount+300;
      let perLicensesWithMarginAmt = perLicensesAmount - calculateMarginAmount(perLicensesAmount);
      let CustomerGivenPrice = parseInt(values.customerPrice);
      if (perLicensesWithMarginAmt < CustomerGivenPrice) {
        mapErrorFlag.push(true);
      } else {
        mapErrorFlag.push(false);
        result = result + 1;
      }
    }

    return result == 0 ? true : false;
  };

  const calculateMarginAmount = (perLicensesAmount: any) => {
    return perLicensesAmount * (priceVarifyPercentage / 100);
  };
  // const comparePriceWithGoogleGivenPrice = (values: any) => (values.customerPrice - values.googlePrice) >= 300;

  const comparePriceWithGoogleGivenPrice = (values: any) => {
    let result = 0;
    let googlePriceNum = parseFloat(values.googlePrice);
    let customerPriceNum = parseFloat(values.customerPrice);
    let difference = customerPriceNum - googlePriceNum;
    let percentageOfGooglePrice = googlePriceNum * (priceVarifyPercentage / 100);

    if (difference >= percentageOfGooglePrice) {
    } else {
      result += 1;
    }

    return result == 0 ? true : false;
  };

  const handleProductType = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const { name, value } = e.target;
    if (name) {
      setValues({
        ...values,
        [name]: value,
      });
    }
    setFormError(null);
  };
  // const populateSubplans = (productType: any) => {
  //   const subplanDropdown = subplans[productType].map((subplan: any, index: any) => (
  //     <option key={index} value={subplan.toLowerCase()}>
  //       {subplan}
  //     </option>
  //   ));
  //   return subplanDropdown;
  // };

  return (
    <form>
      <div className="grid grid-cols-5 gap-3 align-center mb-5 frm1">
        <div className="col-span-2 relative">
          <label className="block mb-2">Plan</label>
          <select id="tran" name="productType" className="p-2.5 w-full cursor-pointer"
            value={values.productType} onChange={handleProductType}>
            <option value="business">Business</option>
            <option value="enterprise">Enterprise</option>
            <option value="frontline">Frontline</option>
          </select>
          {/* <span className="material-symbols-outlined absolute right-3 top-4 cursor-pointer">keyboard_arrow_down</span> */}
          {errors.productType && <div className="error">{errors.productType}</div>}
        </div>
        <div className="col-span-2 relative">
          <label className="block mb-2">SKU</label>
          <select id="peri" name="subPlan" className="w-full p-2.5 cursor-pointer"
            value={values.subPlan} onChange={handleChange}>
            {/* {values.productType && populateSubplans(values.productType)} */}
            {values.productType === "business" && (
              <>
                <option value="starter">Starter</option>
                <option value="standard">Standard</option>
                <option value="plus">Plus</option>
              </>
            )}
            {values.productType === "enterprise" && (
              <>
                <option value="starter">Starter</option>
                <option value="standard">Standard</option>
                <option value="plus">Plus</option>
              </>
            )}
            {values.productType === "frontline" && (
              <>
                <option value="starter">Basic</option>
                <option value="standard">Plus</option>
              </>
            )}
          </select>
          {/* <span className="material-symbols-outlined absolute right-3 top-4 cursor-pointer">keyboard_arrow_down</span> */}

          {errors.subPlan && <div className="error">{errors.subPlan}</div>}
        </div>
        <div className="col-span-1">
          <label className="block mb-2">Licenses</label>
          <input type="number" id="licenses" name="licenses" className="block w-full p-2.5"
            value={values.licenses} maxLength={3} onChange={handleChange} />
          {errors.licenses && <div className="error">{errors.licenses}</div>}
        </div>
      </div>

      <div className="grid grid-cols-5 gap-2 align-center mb-5 frm1">
        {props.googleGivenPriceShow ? (
          <div className="col-span-2">
            <label className="block mb-2">Google Price</label>
            <input type="text" id="googlePrice" name="googlePrice" className="block w-full p-2.5"
              value={values.googlePrice} onChange={handleChange} onKeyPress={handleKeyPress} />
            {errors.googlePrice && <div className="error">{errors.googlePrice}</div>}
          </div>
        ) : null}

        <div className="col-span-2 ">
          <label className="block mb-2">Customer Price</label>
          <input type="text" id="customerPrice" name="customerPrice" className="block w-full p-2.5"
            value={values.customerPrice} onChange={handleChange} onKeyPress={handleKeyPress} />
          {errors.customerPrice && <div className="error">{errors.customerPrice}</div>}
        </div>

        {props.googleGivenPriceShow ? (
          <div className="col-span-1 ml-[1px]">
            <div>
              <button type="button" className="n-user1" onClick={handleButtonClick} id="addProductBtn">
                ADD <span className="material-symbols-outlined font-semibold  text-[20px] xl:text-[22px] 2xl:text-[24px]">add</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="col-span-3 flex justify-end">
            <div>
              <button type="button" className="n-user1" onClick={handleButtonClick} id="addProductBtn">
                ADD <span className="material-symbols-outlined font-semibold text-[20px] xl:text-[22px] 2xl:text-[24px]">add</span>
              </button>
            </div>
          </div>
        )}
        {/* <pre>{JSON.stringify(errors, undefined, 2)}</pre> */}
      </div>
      {formError && <div className="error">{formError}</div>}
    </form>
  );
};

export default GWSProductItemForm;
